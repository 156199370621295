import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { HiMenu } from "react-icons/hi";
import {
  Accordion,
  AccordionDetails,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { UserAdmHeaderProps } from "../interfaces/Students";
import { signOut } from "../services/SingOut";
import user from "../images/students/userDefault.png";
import elias from "../images/home/elias-min.png";
import logo from "../images/home/logo.png";
import nucleoBrand from "../images/home/brand.png";
import { FaUserPlus, FaUserPen } from "react-icons/fa6";
import { MdCreateNewFolder } from "react-icons/md";
import { GiPayMoney } from "react-icons/gi";
import { PiStudentDuotone, PiStudentFill, PiUser } from "react-icons/pi";
import { TfiWrite } from "react-icons/tfi";
import { GrScheduleNew, GrSchedules } from "react-icons/gr";
import { BsListColumnsReverse } from "react-icons/bs";
import { LuPackagePlus } from "react-icons/lu";
import { BiLogOutCircle } from "react-icons/bi";
import { RiImageEditLine } from "react-icons/ri";
import { Fragment, useEffect, useState } from "react";
import AdmsUpdateCourses from "../pages/AdmsUpdateCourses";
import AdmsReportsStudents from "../pages/AdmsReportsStudents";
import AdmsReportsUsers from "../pages/AdmsReportsUsers";
import AdmsPayments from "../pages/AdmsPayments";
import AdmUpdateStudentData from "../pages/AdmUpdateStudentData";
import AdmsCreateCourses from "../pages/AdmsCreateCourses";
import AdmsCreateSchedules from "../pages/AdmsCreateSchedules";
import AdmsViewSchedules from "../pages/AdmsViewSchedules";
import AdmsCreatePackages from "../pages/AdmsCreatePackages";
import AdmsCreateNewUsers from "../pages/AdmsCreateNewUsers";
import AdmsPersonalizates from "../pages/AdmsPersonalizates";
import StorageUserImageComponent from "./StorageUserImageComponent/StorageUserImageComponent";
import { IoHome } from "react-icons/io5";
import { getFileURL } from "../database/storage";
import { FaFileInvoice } from "react-icons/fa";
import AdmsCreateMaterial from "../pages/AdmsCreateMaterial";
import "../styles/AdmHeader.css";
import AdmsViewSchedulesBackup from "../pages/AdmsViewSchedulesBackup";
import AdmsWebClass from "../pages/AdmsWebClass";

const drawerWidth = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "#fff",
  color: "#007b9c",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AdmDrawer(props: UserAdmHeaderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg")); // Check if screen size is less than or equal to "sm"
  const [open, setOpen] = useState(isMobile ? false : true);
  const [expandedItem, setExpandedItem] = useState(null);
  const [admsReports, setAdmsReports] = useState(false);
  const [admsReportsStudents, setAdmsReportsStudents] = useState(false);
  const [admsReportsUsers, setAdmsReportsUsers] = useState(false);
  const [admsPayments, setAdmsPayments] = useState(false);
  const [admsUpdateStudent, setAdmsUpdateStudent] = useState(false);
  const [admsCreateCourses, setAdmsCreateCourses] = useState(false);
  const [admsCreateSchedules, setAdmsCreateSchedules] = useState(false);
  const [admsViewSchedules, setAdmsViewSchedules] = useState(false);
  const [admsViewSchedulesBackup, setAdmsViewSchedulesBackup] = useState(false);
  const [admsCreatePackages, setAdmsCreatePackages] = useState(false);
  const [admsCreateNewUsers, setAdmsCreateNewUsers] = useState(false);
  const [admsPersonalizates, setAdmsPersonalizates] = useState(false);
  const [uploadPhotoPerfil, setUploadPhotoPerfil] = useState(false);
  const [uploadFiles, setUploadFiles] = useState(false);
  const [webClass, setWebClass] = useState(false);

  const [userPhoto, setUserPhoto] = useState(user);

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadPerfilImg = async () => {
      try {
        const imageUrl = await getFileURL(
          `students-photos/${
            props.user ? props.user.uid : localStorage.getItem("code")
          }.jpg`
        );
        if (imageUrl) {
          setUserPhoto(imageUrl);
        } else {
          setUserPhoto(user);
        }
      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadPerfilImg();
  }, []);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const nome = props.nome ? props.nome : "Nome do Usuário";

  // Dividindo a string em palavras
  const partesDoNome = nome.split(" ");

  // Obtendo o primeiro e o último nome
  const primeiroNome = partesDoNome[0];

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  const resetState = () => {
    setOpen(false);
    setExpandedItem(null);
    setAdmsReports(false);
    setAdmsReportsUsers(false);
    setAdmsReportsStudents(false);
    setAdmsPayments(false);
    setAdmsUpdateStudent(false);
    setAdmsCreateCourses(false);
    setAdmsCreateSchedules(false);
    setAdmsViewSchedules(false);
    setAdmsViewSchedulesBackup(false);
    setAdmsCreatePackages(false);
    setAdmsCreateNewUsers(false);
    setAdmsPersonalizates(false);
    setUploadPhotoPerfil(false);
    setUploadFiles(false);
    setWebClass(false)
  };

  const openWebClass = () => {
    updateState([() => setWebClass(true)]);
  };

  const updateState = (updates: any[]) => {
    resetState();
    updates.forEach((update) => update());
  };

  const openRelatorios = () => {
    updateState([() => setAdmsReports(true)]);
  };

  const openRelatoriosUsers = () => {
    updateState([() => setAdmsReportsUsers(true)]);
  };

  const openRelatoriosStudents = () => {
    updateState([() => setAdmsReportsStudents(true)]);
  };

  const createTurmas = () => {
    updateState([() => setAdmsCreateCourses(true)]);
  };

  const createSchedules = () => {
    updateState([() => setAdmsCreateSchedules(true)]);
  };

  const openSchedules = () => {
    updateState([() => setAdmsViewSchedules(true)]);
  };

  const openSchedulesBackup = () => {
    updateState([() => setAdmsViewSchedulesBackup(true)]);
  };

  const createAndViewPacotes = () => {
    updateState([() => setAdmsCreatePackages(true)]);
  };

  const createNewUsers = () => {
    updateState([() => setAdmsCreateNewUsers(true)]);
  };

  const confirmPayments = () => {
    updateState([() => setAdmsPayments(true)]);
  };

  const editStudents = () => {
    updateState([() => setAdmsUpdateStudent(true)]);
  };

  const deliveryTexts = () => {
    setExpandedItem(null);
    const url = "https://nucleo-de-redacao.ecorrecao.com.br/student/login";
    window.open(url, "_blank");
  };

  const openMaterialsPage = () => {
    setExpandedItem(null);
    const url = "https://aluno.eliasancelmo.com.br/";
    window.open(url, "_blank");
  };

  const openSimuladosPage = () => {
    setExpandedItem(null);
    const url = "https://app.minhaprova.com.br";
    window.open(url, "_blank");
  };

  const editPage = () => {
    updateState([() => setAdmsPersonalizates(true)]);
  };

  const backHome = () => {
    resetState();
  };

  const changePhoto = () => {
    updateState([() => setUploadPhotoPerfil(true)]);
  };

  const uploadFilesToStorage = () => {
    updateState([() => setUploadFiles(true)]);
  };

  const role = props.role;

  const navigationIcons = [
    {
      label: "Página Inicial",
      icon: <IoHome />,
      active: true,
      click: backHome,
    },
    {
      label: "Alunos",
      icon: <BsListColumnsReverse />,
      active: role === "corretor" ? false : true,
      subitems: [
        {
          label: "Lista de Alunos",
          icon: <PiStudentFill />,
          click: openRelatoriosStudents,
        },
        {
          label: "Confirmar Pagamentos",
          icon: <GiPayMoney />,
          active: role === "corretor" ? false : true,
          click: confirmPayments,
        },
        {
          label: "Editar / Remover Alunos",
          icon: <FaUserPen />,
          active: role === "corretor" ? false : true,
          click: editStudents,
        },
      ],
    },
    {
      label: "Cursos / Turmas",
      icon: <BsListColumnsReverse />,
      active: role === "corretor" ? false : true,
      subitems: [
        {
          label: "Relatório dos Cursos",
          icon: <BsListColumnsReverse />,
          click: openRelatorios,
        },
        {
          label: "Criar turmas",
          icon: <MdCreateNewFolder />,
          active: role === "corretor" ? false : true,
          click: createTurmas,
        },
        {
          label: "Relatórios de Usuários Adms",
          icon: <PiUser />,
          click: openRelatoriosUsers,
        },
      ],
    },
    {
      label: "Atendimentos",
      icon: <GrScheduleNew />,
      active: true,
      subitems: [
        {
          label: "Criar Atendimentos",
          icon: <GrScheduleNew />,
          active: true,
          click: createSchedules,
        },
        {
          label: "Visualizar Atendimentos",
          icon: <GrSchedules />,
          active: true,
          click: openSchedules,
        },
        {
          label: "Atendimentos - Backup",
          icon: <GrSchedules />,
          active: role === "corretor" ? false : true,
          click: openSchedulesBackup,
        },
      ],
    },
    {
      label: "Criar/Visualizar Pacotes",
      icon: <LuPackagePlus />,
      active: role === "corretor" ? false : true,
      click: createAndViewPacotes,
    },
    {
      label: "Cadastrar Novos Usuários",
      icon: <FaUserPlus />,
      active: role === "corretor" ? false : true,
      click: createNewUsers,
    },
    {
      label: "Entrega de Redações",
      icon: <TfiWrite />,
      active: true,
      click: deliveryTexts,
    },
    {
      label: "Plataforma de Materiais",
      icon: <PiStudentFill />,
      active: role === "corretor" ? false : true,
      click: openWebClass,
    },
    // {
    //   label: "Plataforma dos Simulados",
    //   icon: <PiStudentDuotone />,
    //   active: role === "corretor" ? false : true,
    //   click: openSimuladosPage,
    // },
    {
      label: "Inserir materiais",
      icon: <FaFileInvoice />,
      active: role === "corretor" ? false : true,
      click: uploadFilesToStorage,
    },
    
    {
      label: "Personalizar Plataforma",
      icon: <RiImageEditLine />,
      active: role === "corretor" ? false : true,
      click: editPage,
    },
    {
      label: "Sair",
      icon: <BiLogOutCircle />,
      active: true,
      click: handleLogout,
    },
    // Adicione mais objetos conforme necessário
  ];

  const handleItemClick = (
    index: any,
    clickFunction: any,
    hasSubitems: boolean
  ) => {
    if (expandedItem === index) {
      setExpandedItem(null);
    } else {
      if (hasSubitems) {
        clickFunction();
      } else {
        setExpandedItem(index);
      }
    }
  };
  

  const DrawerList = (
    <>
      <Box
        sx={{
          width: drawerWidth,
          position: "relative",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        role="presentation"
      >
        <DrawerHeader
          style={{
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: open ? "20px" : 0,
            width: "100%",
            marginTop: "20px",
          }}
        >
          {open && (
            <>
              <div
                style={{
                  width: "100%",
                  justifyContent:
                    theme.direction === "rtl" ? "flex-start" : "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                <IconButton
                  onClick={toggleDrawer(false)}
                  sx={{ color: "#007b9c" }}
                >
                  <AiOutlineClose />
                </IconButton>
              </div>

              <div
                className="header-img-container"
                onClick={changePhoto}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={userPhoto}
                  alt="Imagem do usuário"
                  style={{
                    width: "120px",
                    height: "120px",
                    marginBottom: "20px",
                  }}
                  className="header-img"
                />
                <p
                  className="header-name"
                  style={{ color: "black", fontSize: "16px" }}
                >{`Olá, ${primeiroNome}`}</p>
              </div>
            </>
          )}
        </DrawerHeader>
        <Divider />
        <div
          style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "50%" }}
        >
          <List>
            {navigationIcons.map((item, index) => (
              <Fragment key={index}>
                {item.active && (
                  <Fragment>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "initial",
                          px: 2.5,
                        }}
                        onClick={() =>
                          handleItemClick(index, item.click, !item.subitems)
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#007b9c",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.label}
                          sx={{ opacity: open ? 1 : 0, color: "#007b9c" }}
                        />
                      </ListItemButton>
                    </ListItem>
                    {expandedItem === index && item.subitems && (
                      <Accordion>
                        <AccordionDetails
                          sx={{ padding: open ? "8px 16px 16px" : 0 }}
                        >
                          <List>
                            {item.subitems.map((subitem, subindex) => (
                              <ListItem
                                key={subindex}
                                disablePadding
                                sx={{ display: "block" }}
                              >
                                <ListItemButton
                                  onClick={subitem.click}
                                  sx={{
                                    minHeight: 48,
                                    justifyContent: "initial",
                                    px: 2.5,
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: open ? 3 : "auto",
                                      justifyContent: "center",
                                      color: "#007b9c",
                                    }}
                                  >
                                    {subitem.icon}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={subitem.label}
                                    sx={{
                                      opacity: open ? 1 : 0,
                                      color: "#007b9c",
                                    }}
                                  />
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Fragment>
                )}
              </Fragment>
            ))}
          </List>
        </div>

        <Divider />

        {open && (
          <div className="header-img-container">
            <img
              src={nucleoBrand}
              alt="Imagem da marca"
              style={{
                width: "150px",
                height: "130px",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            />
          </div>
        )}
      </Box>
    </>
  );

  const homePage = (
    <>
      {!admsReports &&
        !admsReportsStudents &&
        !admsReportsUsers &&
        !admsPayments &&
        !admsUpdateStudent &&
        !admsCreateCourses &&
        !admsCreateSchedules &&
        !admsViewSchedules &&
        !admsViewSchedulesBackup &&
        !admsCreatePackages &&
        !admsCreateNewUsers &&
        !admsPersonalizates &&
        !uploadPhotoPerfil &&
        !uploadFiles &&
        !webClass && (
          <div style={{ height: "100%", minHeight: "100vh" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={elias} alt="elias foto" width={isMobile ? 340 : 600} />
              <div style={{ width: "100%", maxWidth: "1200px" }}>
                <p style={{ color: "white", textAlign: "justify" }}>
                  É com imensa alegria que dou as boas-vindas ao espaço
                  especialmente criado para vocês, meus estimados colaboradores
                  do Núcleo de Redação Elias Ancelmo. Este ambiente foi
                  concebido com carinho para promover a excelência de nosso
                  trabalho e o crescimento mútuo de nossa equipe. Que este seja
                  um lugar de inspiração, colaboração e realização de grandes
                  conquistas!
                </p>
                <p style={{ color: "white", textAlign: "justify" }}>
                  Para garantir que todas as suas necessidades sejam atendidas
                  de forma rápida e eficiente, colocamos à disposição uma ampla
                  gama de serviços no lado esquerdo ou no canto superior
                  esquerdo da tela. Desde orientações sobre projetos até suporte
                  técnico, estamos aqui para ajudá-los a alcançar todo o seu
                  potencial. Sintam-se à vontade para explorar e utilizar esses
                  recursos sempre que necessário. Estamos aqui para tornar sua
                  experiência no Núcleo de Redação Elias Ancelmo ainda mais
                  gratificante e produtiva.
                </p>
              </div>
              <img src={logo} alt="elias logo" width={250} />
            </div>
          </div>
        )}
    </>
  );

  return (
    <div className="drawer-container">
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
              borderRadius: "4px",
              // ":hover": {
              //   backgroundColor: "#9c9b9b",
              // }
            }}
          >
            <HiMenu />
            <Typography variant="h6" noWrap component="span" sx={{ ml: 1 }}>
              Serviços
            </Typography>
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Núcleo de Redação - Elias Ancelmo
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>

      {/* PAGES DE CONTEÚDOS */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: open ? `calc(100vw - ${2 * drawerWidth}px)` : "100vw",
          ml: open ? 3 : 0,
        }}
      >
        <DrawerHeader />

        {admsReports && (
          <AdmsUpdateCourses
            state={{ role: props.role, cursos: props.curso, user: props.user }}
          />
        )}

        {admsReportsUsers && (
          <AdmsReportsUsers
            state={{ role: props.role, cursos: props.curso, user: props.user }}
          />
        )}

        {admsReportsStudents && (
          <AdmsReportsStudents
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
            }}
          />
        )}

        {admsPayments && (
          <AdmsPayments
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
            }}
          />
        )}

        {admsUpdateStudent && (
          <AdmUpdateStudentData
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
            }}
          />
        )}

        {admsCreateCourses && (
          <AdmsCreateCourses
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
            }}
          />
        )}

        {admsCreateSchedules && (
          <AdmsCreateSchedules
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
            }}
          />
        )}

        {admsViewSchedules && (
          <AdmsViewSchedules
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
            }}
          />
        )}

        {admsViewSchedulesBackup && <AdmsViewSchedulesBackup />}

        {admsCreatePackages && (
          <AdmsCreatePackages
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
            }}
          />
        )}

        {admsCreateNewUsers && (
          <AdmsCreateNewUsers
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
            }}
          />
        )}

        {admsPersonalizates && (
          <AdmsPersonalizates
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
            }}
          />
        )}

        {uploadPhotoPerfil && (
          <StorageUserImageComponent uid={props.user.uid} />
        )}

        {uploadFiles && (
          <AdmsCreateMaterial
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
            }}
          />
        )}

        {webClass && (
          <AdmsWebClass
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user
            }}
          />
        )}

        {homePage}
      </Box>
    </div>
  );
}
