import React, { FormEvent, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/esm/Form";
import copy from "copy-to-clipboard";
import { QrCodePix } from "qrcode-pix";
import { AlunoPackages, StudentData } from "../interfaces/Students";
import { PackageData } from "../interfaces/Turmas";
import {
  getPackagesFirestore,
  updatePackageFirestore,
} from "../database/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import Alerts from "../components/Alert";

interface PayProps {
  valor: number;
  aluno: StudentData | any;
}

export default function StudentPacotes(props: PayProps) {

  const [isPaymented, setIsPaymented] = useState(true);
  const [alertText, setAlertText] = useState("");
  const [alerts, setAlerts] = useState(false);
  const [qrCode, setQrCode] = useState<string>("");
  const [rawPix, setRawPix] = useState<string>("");
  const [name, setName] = useState(props.aluno ? props.aluno.name : "");
  const [email, setEmail] = useState(props.aluno ? props.aluno.email : "");
  const [cpf, setCpf] = useState(props.aluno ? props.aluno.cpf : "");
  const [celular, setCelular] = useState(
    props.aluno ? props.aluno.celularAluno : ""
  );
  const [pacote, setPacote] = useState("");
  const [pacoteName, setPacoteName] = useState("");
  const [valor, setValor] = useState<number>(0);
  const [showQrCode, setShowQrCode] = useState(false);
  const [packages, setPackages] = useState<PackageData[]>([]);

  useEffect(() => {
    const loadPacotes = async () => {
      try {
        let pacotes = await getPackagesFirestore();
        setPackages(pacotes);
      } catch (error) {
        console.error(`Erro ao buscar pacotes: ${error}`);
      }
    };

    loadPacotes();
  }, []);

  useEffect(() => {
    async function generateDynamicPix() {
      const qrCodePix = QrCodePix({
        version: "01",
        key: "eliasancelmoo19@gmail.com",
        name: "Elias Ancelmo",
        city: "LIMOEIRO",
        transactionId: "redacao2024elias",
        message: "Pacotes de redação 2024",
        value: valor,
        cep: "55700000",
        currency: 986,
        countryCode: "BR",
      });

      const rawPixStr = qrCodePix.payload();
      const qrCodeBase64 = await qrCodePix.base64();

      setRawPix(rawPixStr);
      setQrCode(qrCodeBase64);
    }

    void generateDynamicPix();
  }, [pacote, valor]);

  function copyToClipboard(pixCode: string) {
    copy(pixCode);

    setAlertText(`Você copiou o código PIX: ${pixCode}`);
  }

  function cpfMask(value: string) {
    return value
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  function phoneMask(valor: string) {
    return valor
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/g, "($1) $2")
      .replace(/(\d)(\d{4})$/, "$1-$2");
  }

  function _cpf(cpf: string) {
    cpf = cpf.replace(/[^\d]+/g, "");

    if (cpf === "") return false;

    if (
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    )
      return false;
    let add = 0;

    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev !== parseInt(cpf.charAt(9))) {
      return false;
    }

    add = 0;

    for (let i = 0; i < 10; i++) {
      add += parseInt(cpf.charAt(i)) * (11 - i);
    }

    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev !== parseInt(cpf.charAt(10))) {
      return false;
    }

    return true;
  }

  function validarCPF(el: any) {
    if (!_cpf(el.value)) {
      alert("CPF inválido!" + el.value);

      // apaga o valor
      el.value = "";
    }
  }

  async function handleSubscribe(event: FormEvent) {
    event.preventDefault();

    const pacoteSelecionado: any = packages.find(
      (p) => p.pacoteCode === pacote
    );

    let alunosArray: Array<AlunoPackages> =
      pacoteSelecionado?.alunosQueCompraram ?? [];

    const aluno = {
      id: alunosArray.length + 1,
      cpf: cpf,
      nome: name,
      telefone: celular,
      dataDeCompra: Date.now().toString(),
      email: email,
    };

    alunosArray.push(aluno);

    // console.log(pacoteSelecionado?.qtdPacotes - 1);

    const alunoPackage: any = {
      ...pacoteSelecionado,
      alunosQueCompraram: alunosArray,
      qtdPacotes: pacoteSelecionado?.qtdPacotes - 1,
    };

    // console.log(alunoPackage);

    try {
      await updatePackageFirestore(alunoPackage);
    } catch (error) {
      console.error("Erro ao atualizar pacote:", error);
    }

    setShowQrCode(true);
  }

  const handleWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?phone=558199592264&text=Acabei%20de%20efetuar%20a%20compra%20do%20meu%20${pacoteName}%20de%20redações!%20Segue%20abaixo%20meu%20comprovante%20de%20pagamento.`;
    window.open(url, "_blank");
  };

  return (
      <div style={{backgroundColor: "#ffffff", borderRadius: "10px", padding: "20px 0", maxWidth: "650px", margin: '0 auto'}}>
        <div style={{ textAlign: "center" }}>
          <div
            style={{ width: "100%", color: "#000", textAlign: "center" }}
          >
            PACOTES DE REDAÇÃO 2024
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              color: "#000",
              alignItems: "center",
            }}
          >
            {!showQrCode && (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "0 2.5rem",
                  }}
                >
                  <form
                    onSubmit={handleSubscribe}
                    className="was-validated"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <label className="mb-2 bold" htmlFor="name">
                        Nome completo:
                      </label>
                      <input
                        type="text"
                        id="name"
                        onChange={(event) => setName(event.target.value)}
                        value={name}
                        autoComplete="false"
                        required
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "auto",
                      }}
                    >
                      <label className="mb-2 bold">CPF:</label>
                      <input
                        type="text"
                        id="cpf"
                        minLength={14}
                        maxLength={14}
                        value={cpf}
                        onChange={(event) =>
                          setCpf(cpfMask(event.target.value))
                        }
                        onBlur={(event) => {
                          validarCPF(event.target);
                        }}
                        required
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <label className="mb-2 bold" htmlFor="name">
                        E-mail:
                      </label>
                      <input
                        type="email"
                        id="name"
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                        autoComplete="false"
                        required
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "auto",
                      }}
                    >
                      <label className="mb-2 bold">Número do Celular: </label>
                      <input
                        type="text"
                        id="celular"
                        value={celular}
                        minLength={14}
                        maxLength={15}
                        onChange={(event) =>
                          setCelular(phoneMask(event.target.value))
                        }
                        required
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "auto",
                      }}
                    >
                      <label className="mb-2 bold" htmlFor="pacote">
                        Escolha o pacote que deseja contratar:
                      </label>
                      <select
                        id="pacote"
                        value={pacote}
                        onChange={(event) => {
                          setPacote(event.target.value);
                          const pacoteSelecionado = packages.find(
                            (p) => p.pacoteCode === event.target.value
                          );
                          if (pacoteSelecionado) {
                            setValor(pacoteSelecionado.valorNumber);
                            setPacoteName(pacoteSelecionado.nomeDoPacote);
                          } else {
                            setValor(0);
                            setPacoteName("");
                          }
                        }}
                        required
                      >
                        <option value="" disabled hidden>
                          Selecione uma opção
                        </option>
                        {packages.map((p) => (
                          <option
                            key={p.pacoteCode}
                            value={p.pacoteCode}
                            disabled={p.qtdPacotes <= 0}
                          >
                            {p.nomeDoPacote}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button
                      type="submit"
                      //disabled={loading}
                      className="btn btn-primary"
                      style={{
                        textTransform: "uppercase",
                        maxWidth: "360px",
                        alignSelf: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Garantir meu pacote de redações
                    </button>
                  </form>
                </div>
              </>
            )}

            {showQrCode && (
              <>
                <div style={{ display: "flex", width: "100%" }}>
                  <h2
                    style={{
                      width: "100%",
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    Basta escanear o QR Code abaixo ou copiar o código e enviar
                    para o meu PIX
                  </h2>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={qrCode}
                    alt={"QR Code PIX"}
                    width={250}
                    height={250}
                  />
                  {/*<p className="text-black text-center w-full text-[12px]">
                  {rawPix}
                </p>*/}
                </div>
              </>
            )}
          </div>

          {showQrCode && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Alerts show={alerts} content={alertText} type="danger" />
                <Button
                  className="btn"
                  onClick={() => {
                    copyToClipboard(rawPix);
                    setAlerts(true);
                    setTimeout(() => {
                      setAlerts(false);
                    }, 5000);
                  }}
                >
                  COPIAR PIX CODE
                </Button>
              </div>
              <br />
              <Form>
                <div id="inline-checkbox" className="mb-3 text-black">
                  <Form.Check
                    inline
                    label="Confirmo o pagamento e agora vou enviar o comprovante para o Professor Elias"
                    name="group1"
                    type="checkbox"
                    id="inline-checkbox"
                    onChange={(event) => setIsPaymented(!event.target.checked)}
                  />
                </div>
              </Form>
            </>
          )}
        </div>
        <div>
          {showQrCode && (
            <Button
              disabled={isPaymented}
              variant="primary"
              onClick={handleWhatsApp}
              className="btn btn-primary"
            >
              ENVIAR COMPROVANTE
            </Button>
          )}

          {/* <Button
            variant="secondary"
            onClick={() => {
              //props.close();
            }}
            className="btn btn-secondary"
          >
            CANCELAR
          </Button> */}
        </div>
      </div>
  );
}
